@import 'config/colors.scss';

@mixin button($color-start, $color-end) {
  background-image: linear-gradient(270deg, $color-start 0%, $color-end 100%);
  box-shadow: 0px 14px 10px -2px lighten($color-start, 20%);

  &:hover {
    background-image: linear-gradient(
      270deg,
      darken($color-start, 5%) 0%,
      darken($color-end, 5%) 100%
    );
  }
}

.form-button {
  width: 253px;
  height: 65px;
  border-radius: 42px;
  color: #ffffff;
  font-family: Geometria;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.06px;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    width: 239px;
    height: 60px;
  }
  @media screen and (max-width: 650px) {
    width: 184px;
    height: 47px;
    font-size: 16px;
  }

  &.small {
    width: 203px;
    height: 53px;
    border-radius: 42px;
    font-size: 16px;

    @media screen and (max-width: 1280px) {
      width: 160px;
      height: 43px;
      font-size: 15px;
    }
    @media screen and (max-width: 650px) {
      width: 150px;
      height: 37px;
      font-size: 13px;
    }
  }
  &.tiny {
    width: 163px;
    height: 43px;
    border-radius: 42px;
    font-size: 14px;

    @media screen and (max-width: 1280px) {
      width: 163px;
      height: 43px;
      font-size: 14px;
    }
    @media screen and (max-width: 650px) {
      width: 163px;
      height: 43px;
      font-size: 14px;
    }
  }
  &.right {
    float: right;
  }
  &.primary {
    @include button(#69befc, #3ca7fb);
  }
  &.orange {
    @include button(#f6bb45, #e8861e);
  }
  &.dark {
    @include button(#d3d3d3, #d4d4d4);
  }
}
