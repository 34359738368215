@import 'config/colors.scss';

.dropdown-container {
  position: relative;
  cursor: pointer;

  .dropdown-toggle {
    display: flex;
    align-items: center;

    span {
      color: #f19533;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.05px;
    }
    img {
      object-fit: contain;
      width: 14px;
      height: 9px;
      margin-left: 22px;
    }
    .drop-down-button {
      width: 50px;
      height: 50px;
      margin-left: 0;
    }
  }
  .dropdown-list {
    position: absolute;
    top: calc(100% + 5px);
    left: -20px;
    width: 156px;
    box-shadow: 0 2px 24px rgba(73, 73, 73, 0.2);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 14px 0 14px 18px;

    &.wide {
      width: 250px;
    }

    .dropdown-list__item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }

      button {
        padding-left: 0;
      }
      &:hover {
        opacity: 0.5;
      }
      span {
        color: #383838;
        font-family: Geometria;
        font-weight: 500;
      }
    }
  }
}
