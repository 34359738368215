@charset "utf-8";
@import 'config/colors.scss';
@import '~bulma/bulma.sass';

// Import a Google Font with display swap
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&font-display=swap');

@font-face {
  font-family: 'Geometria';
  font-weight: 700;
  src: url('resources/fonts/Geometria-Bold.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  font-weight: 500;
  src: url('resources/fonts/Geometria-Regular.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  font-weight: 400;
  src: url('resources/fonts/Geometria-Light.woff');
  font-display: swap;
}

// Update Bulma's global variables
$family-sans-serif: 'Roboto', sans-serif;

$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $kinda-white;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// common style
* {
  box-sizing: border-box;
  outline: none;
  font-family: Geometria;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.policy {
  max-height: 7500px !important;

  p {
    margin: 0.5rem 0;
    padding-left: 0.8rem;
  }

  .m-0 {
    margin: 0 !important;
  }

  .p-l-0 {
    padding-left: 0 !important;
  }

  .p-l-2 {
    padding-left: 2rem !important;
  }
}

.font-italic {
  font-style: italic;
}