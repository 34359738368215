@import 'config/colors.scss';

.modal-login {
  width: 440px;
  height: auto;
  padding: 35px 46px;
  margin: 0 auto;
  box-shadow: 0 22px 24px rgba(73, 73, 73, 0.06);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;

  .modal-header {
    position: relative;
    display: inline-block;

    .text {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      h2 {
        color: #575757;
        font-size: 30px;
        font-weight: 700;
        margin-right: 9px;
      }
    }
    .decorations {
      span {
        position: absolute;

        &.times {
          display: inline-block;
          font-size: 17px;
          font-weight: 500;
          color: #f9c762;
        }
        &.round {
          display: inline-block;
          border: 1px solid #f9c762;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        &:nth-child(1) {
          top: 88%;
          right: -6px;
        }
        &:nth-child(2) {
          left: -28px;
          bottom: 10px;
        }
        &:nth-child(3) {
          left: 86px;
          top: 1px;
        }
        &:nth-child(4) {
          right: -34px;
          top: 21px;
        }
        &:nth-child(5) {
          left: 26px;
          top: -4px;
        }
        &:nth-child(6) {
          left: -7px;
          top: 47px;
        }
      }
    }
  }
  .modal-content {
    margin-top: 30px;
    width: unset;
    height: 100%;
    overflow: unset;

    .inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      > div {
        width: 100%;
        margin-bottom: 11px;
        text-align: left;

        &.input-password {
          position: relative;

          .input-password__forgot {
            position: absolute;
            right: 0;
            top: -7px;
            color: #0461fb;
            font-family: Geometria;
            font-size: 13px;
            font-weight: 700;
            line-height: 32px;
            text-decoration: underline;
            border: none;
            background: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .modal-footer {
    margin-top: 25px;

    .already {
      line-height: 30px;
      margin-top: 15px;

      span {
        color: #575757;
        font-size: 14px;
        font-weight: 700;
      }
      button {
        color: #0461fb;
        text-decoration: underline;
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 700;
        font-family: Geometria;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modal-overlay {
    .modal-login {
      width: 90%;
      height: unset;
      padding: 35px 25px;

      .modal-content {
        .inputs {
          > div {
            &.input-password {
              .input-password__forgot {
                left: 0;
                top: 70%;
              }
            }
          }
        }
      }
      .modal-footer {
        margin-top: 40px;
      }
    }
  }
}
