@import 'config/colors.scss';

.navbar-container {
  display: flex;
  padding: 50px 0;
  margin: 0 !important;

  .topmenu {
    &.enabled {
      button {
        color: $dark_blue !important;
      }
    }
  }

  .profile {
    .avatar {
      width: 53px;
      height: 57px;
      margin-left: 16px;
      object-fit: contain;
      &.doctor-avatar {
        width: 53px;
        height: 53px;
        border-radius: 50px;
      }
    }
  }

  .signupBtn {
    border-radius: 42px;
    background-image: linear-gradient(258deg, #f9c762, #f19533);
    min-width: 150px;
    padding: 15px 0;
    cursor: pointer;

    a, button {
      font-family: Geometria;
      color: $white;
      margin: 0 auto;
    }
  }

  .enabled a {
    color: $dark_blue !important;

    &:hover {
      color: $light_blue !important;
    }
  }

  .navMenu {
    font: bold 16px Geometria;
    color: $light_grey;
    margin-right: 35px;
    background: none;
    border: none;
  }

  .mobile {
    display: none;
  }


  
  .level-left img {
    width: 30%;
  
  }

  .level-left {
    flex-shrink: 1;
  }

  .level-item {
    justify-content: left;
  }
  

  @media screen and (max-width: 1000px) {

    .level-right .mobile img {
      width: 154px;
      height: auto;
    }

    .topmenu {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
    .forpc {
      display: none;
    }
    .logo-margin-top-remove {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 650px) {
    .level-left img {
      width: 32px;
      height: 21px;
    }

    

    .level-right {
      margin-top: 0 !important;
    }

    .level-right .mobile img {
      width: 154px;
      height: auto;
    }
  }
}
