@import 'config/colors.scss';
@import '../../../config/variables.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  .modal-signedup {
    width: 624px;
    margin: 0 auto;
    box-shadow: 0 22px 24px rgba(73, 73, 73, 0.06);
    background-color: #ffffff;
    padding: 22px;
    text-align: center;
    border-radius: 10px;

    .image {
      img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin: 0 auto;
      }
    }

    .title {
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      h2 {
        color: #575757;
        font-size: 28px;
        font-weight: 700;
        margin-right: 7px;
        line-height: 28px;
      }
    }

    .description {
      p {
        opacity: 0.6;
        color: #7b7b7b;
        font-family: Roboto;
        font-size: 16px;
        font-weight: $font-weight-roboto-regular;
      }
    }

    .confirm {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 650px) {
  .modal-overlay {
    .modal-signedup {
      width: 360px;

      .title {
        display: block;

        h2 {
          font-size: 24px;
        }
      }

      .description {
        margin: 0 auto;
        width: 80%;
      }
    }
  }
}