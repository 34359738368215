.partner-modal {
  width: auto;
  max-width: 845px;
  height: auto;
  margin: auto;
  padding: 35px 90px 35px 57px;
  box-shadow: 0 22px 24px rgba(73, 73, 73, 0.06);
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;

  .close {
    position: absolute;
    top: 30px;
    right: 34px;
    height: 43px;
    width: 43px;
    border-radius: 50px;
    background: none;
    border: 1px solid #E9E9E9;
    color: #575757;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }

  .benefits {
    display: flex;
  }

  p {
    margin-bottom: 25px;
  }
}
