$purple: #8a4d76;
$grin: #018a5b;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$kinda-white: #f6f6f6;
$kinda-white2: #E9E9E9;

$light_blue: #73c2ff;
$blue: #6ebffe;
$dark_blue: #3ea8fb;
$neon_blue: #0461FB;

$yellow: #f9c762;
$dark_yellow: #f19533;

$grey: #757676;
$grey2: #717070;
$dark_grey: #575757;
$light_grey: #a3a3a3;
$medium_grey: #979797;

$white: #ffffff;

$main_body_text_color: #0c0c0c;