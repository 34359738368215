@import 'config/colors.scss';

#partners {
  margin-top: 122px;

  .title {
    text-align: center;
    margin-bottom: 25px;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .partner-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-color: transparent;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    margin: 1rem;
  }

  .content-flex-grow {
    flex-grow: 1;
  }

  .button-container {
    text-align: center;
    margin-top: auto;
    padding: 1rem;
  }



  @media screen and (max-width: 1088px) {
    padding: 0 34px;


  }

  @media screen and (max-width: 768px) {
    .title {
      text-align: left;
    }
  }

  @media screen and (max-width: 650px) {
    #partners {
      padding: 0 23px;
    }
  }


  .card-content-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.3294117748737335px;
    text-align: left;
    color: $main_body_text_color;
  }


  .media {
    display: flex;
    align-items: center; // This ensures vertical center alignment of all items in the media component
  }

  .media-logo {
    max-height: 48px; // Adjust this as needed
    width: auto; // Keep the original aspect ratio of the image
    object-fit: contain;
    margin-right: 0.5rem; // Adjust the right margin if necessary
    margin-top: 0; // Reset any default margin that might push the logo up
    margin-bottom: 0; // Reset any default margin that might push the logo down
  }


  .card-heading-title {
    font-weight: bold;
    font-size: 18px; // Make sure this is the correct size to match the visual hierarchy with the logo
    display: flex;
    align-items: center; // Vertically align the text if it's also a flex container
    margin-left: 17px; // Adjust the left margin if necessary
  }

}