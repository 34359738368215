@import 'config/colors.scss';

  .modal-signup {
    width: 728px;
    height: auto;
    margin: 0 auto;
    padding: 35px 46px;
    box-shadow: 0 22px 24px rgba(73, 73, 73, 0.06);
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;

    form {
      height: 100%;
    }

    .modal-header {
      position: relative;
      display: inline-block;

      .text {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        h2 {
          color: #575757;
          font-size: 30px;
          font-weight: 700;
          margin-right: 9px;
        }
      }
      .decorations {
        span {
          position: absolute;

          &.times {
            display: inline-block;
            font-size: 17px;
            font-weight: 500;
            color: #0461fb;
          }
          &.round {
            display: inline-block;
            border: 1px solid #0461fb;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
          &:nth-child(1) {
            top: 100%;
            right: -23px;
          }
          &:nth-child(2) {
            left: -46px;
            bottom: -1px;
          }
          &:nth-child(3) {
            left: 59px;
            top: -6px;
          }
          &:nth-child(4) {
            right: -4px;
            top: 97%;
          }
          &:nth-child(5) {
            left: 96px;
            top: -4px;
          }
          &:nth-child(6) {
            left: -27px;
            top: 13px;
          }
        }
      }
    }
    .modal-content {
      margin-top: 30px;
      margin-bottom: 20px;
      width: unset;
      max-height: calc(100% - 136px);

      .inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-panel,
        .right-panel {
          width: 290px;
          align-self: baseline;

          > div {
            text-align: left;
            //margin-bottom: 17px;
          }
        }
      }
      .upload {
        text-align: left;

        .upload-title {
          label {
            color: #575757;
            font-size: 12px;
            font-family: Geometria;
            font-weight: 700;
            letter-spacing: 1.28px;
          }
          
          .optional {
            font-size:11px;
            color: $light_grey;
          }
        }
        .upload-details {
          display: flex;
          align-items: center;

          .upload-details__files {
            display: flex;
            align-items: center;

            .upload-details__files-image {
              position: relative;
              width: 53px;
              height: 53px;
              margin-right: 10px;
              border-radius: 15px;

              &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0.4;
                border-radius: 15px;
              }
              .remove {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: #ffffff url('../../../resources/images/close.png') no-repeat 11px 11px / 13px 13px;
                width: 35px;
                height: 35px;
                opacity: 0.7;
                z-index: 2;
                border-radius: 50%;
                display: block;
                cursor: pointer;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .upload-details__push {
            display: flex;
            align-items: center;
            margin-top: 7px;

            .upload-details__push-image {
              width: 53px;
              height: 53px;
              border-radius: 15px;
              border: 1px solid #f29937;
              background-color: #ffffff;
              position: relative;

              img {
                width: 30px;
                height: 30px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
            .upload-details__push-text {
              margin-left: 18px;
              cursor: pointer;

              label {
                color: #f19533;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: -0.06px;
                line-height: 54px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      .signin {
        line-height: 54px;

        span {
          color: #575757;
          font-size: 14px;
          font-weight: 700;
        }
        button {
          color: #0461fb;
          text-decoration: underline;
          border: none;
          background: none;
          font-size: 14px;
          font-weight: 700;
          font-family: Geometria;
        }
      }
    }
  }

@media screen and (max-width: 1000px) {
  .modal-overlay {
    .modal-signup {
      width: 87%;
      padding: 35px 20px;

      .modal-content {
        .inputs {
          .left-panel,
          .right-panel {
            width: 229px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modal-overlay {
    .modal-signup {
      width: 87%;
      .modal-content {
        .inputs {
          flex-direction: column;

          .left-panel,
          .right-panel {
            width: 100%;

            > div {
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }
}
