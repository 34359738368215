@import 'config/colors.scss';
@import '../../../config/variables.scss';

.form-input {
  min-height: 100px;

  &.multiple {
    min-height: 240px;
  }

  label {
    margin-left: 10px;
    color: #575757;
    font-size: 12px;
    font-family: Geometria;
    font-weight: 700;
    letter-spacing: 1.28px;
    display: block;
    text-transform: uppercase;
  }

  input,
  textarea {
    width: 100%;
    height: 57px;
    color: #7b7b7b;
    border-radius: 29px;
    border: 1px solid #ededed;
    background-color: #ffffff;
    font-family: $font-weight-roboto-regular;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.4px;
    padding-left: 30px;
    margin-top: 7px;
    outline: none;

    &::placeholder {
      opacity: 0.3;
      color: #7b7b7b;
    }

    &:focus {
      border: 1px solid #45abfb;
    }

    &.error {
      border: 1px solid #f28585;
    }

    &.success {
      background: white url('../../../resources/images/check-blue.svg') no-repeat 93% 50%;
    }
  }

  input {
    &.small {
      height: 43px;
      font-size: 13px;
      padding-right: 50px;
    }
  }

  textarea {
    height: 174px;
    padding-top: 14px;
    border-radius: 15px;
    resize: none;
    padding-right: 50px;
  }

  select {
    width: 100%;
    height: 43px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #ededed;

  }

  .message {
    background-color: white;
    color: #f28585;
    font-size: 13px;
    font-family: Roboto;
    font-weight: 500;
    margin-left: 30px;
    display: inline-block;
    line-height: 32px;
  }

  @media screen and (max-width: 650px) {

    input,
    textarea {
      font-size: 13px;
      height: 40px;
    }

    textarea {
      height: 150px;
    }
  }
}