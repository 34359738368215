@import '../../../config/colors.scss';
@import '../../../config/variables.scss';


.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  .modal-reset {
    margin: 0 auto;
    width: 480px;
    height: 440px;
    padding: 35px 46px;
    box-shadow: 0 22px 24px rgba(73, 73, 73, 0.06);
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;

    .modal-header {
      position: relative;
      display: inline-block;

      .text {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        h2 {
          color: #575757;
          font-size: 30px;
          font-weight: 700;
          margin-right: 9px;
        }
      }

      .decorations {
        span {
          position: absolute;

          &.times {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            color: #f9c762;
          }

          &.round {
            display: inline-block;
            border: 1px solid #f9c762;
            width: 7px;
            height: 7px;
            border-radius: 50%;
          }

          &:nth-child(1) {
            top: 88%;
            right: -6px;
          }

          &:nth-child(2) {
            left: -22px;
            top: -10px;
          }

          &:nth-child(3) {
            left: 50px;
            top: -7px;
          }

          &:nth-child(4) {
            right: 22px;
            top: 7px;
          }
        }
      }
    }

    .modal-content {
      margin-top: 30px;
      width: unset;

      .description {
        width: 286px;
        margin: 0 auto;

        p {
          opacity: 0.6;
          color: #7b7b7b;
          font-family: Roboto;
          font-size: 17px;
          font-weight: $font-weight-roboto-regular;
          letter-spacing: 0.4px;
        }
      }

      .email {
        width: 100%;
        margin-top: 16px;
        text-align: left;
      }
    }

    .modal-footer {
      margin-top: 48px;
    }
  }
}

@media screen and (max-width: 650px) {
  .modal-overlay {
    .modal-reset {
      width: 90%;
      height: unset;
      padding: 35px 25px;
    }
  }
}