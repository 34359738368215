@import 'config/colors.scss';

.lines {
  &.orange {
    span {
      background-image: linear-gradient(231deg, #f9c762 0%, #f19533 100%);
    }
  }
  &.small {
    span {
      height: 5px;

      &.line-long {
        width: 34px;
      }
      &.line-short {
        width: 9px;
        margin-left: 5px;
      }
    }
  }

  span {
    display: inline-block;
    height: 9px;
    border-radius: 7px;
    background-image: linear-gradient(270deg, #69befc 0%, #3ca7fb 100%);

    &.line-long {
      width: 67px;
    }
    &.line-short {
      width: 17px;
      margin-left: 9px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .lines {
    span {
      height: 7px;

      &.line-long {
        width: 50px;
      }
      &.line-short {
        width: 13px;
      }
    }
  }
}
