@import 'config/colors.scss';

#testimonials {
  margin-top: 20px;

  .image-wrapper {
    text-align: center;
  }

  @media screen and (max-width: 1088px) {
    padding: 0 34px;
  }


  @media screen and (max-width: 768px) {
    .columns {
      display: flex;
      flex-direction: column-reverse;
    }

    .image-wrapper, .users {
      padding: 0 23px;
      margin-top: 35px;
    }
  }

  .users {
    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      margin-top: 44px;
      white-space: pre-wrap;
    }

    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      margin-top: 30px;
    }

    .buttons {
      display: flex;
      margin-top: 73px;

      button {
        height: 34px;
        width: 34px;
        border-radius: 30px;
        border: 1px solid $kinda-white2;
        background-color: transparent;
        color: $grey2;

        margin-right: 10px;

        &:hover, &.active {
          background-color: $neon_blue;
          border-color: $neon_blue;
          color: $white;
        }
      }
    }
  }
}
