@import 'config/colors.scss';
@import '../../config/variables.scss';

body {
  .error_msg {
    color: $pink;
    margin: 5px 0px;
    font-family: Geometria;
    font-weight: 700;
    letter-spacing: 1.28px;
  }

  .success_msg {
    color: $dark_grey;
    margin: 5px 0px;
    font-family: Geometria;
    font-weight: 700;
    letter-spacing: 1.28px;
    text-align: center;
  }
}

.btnGroup {
  display: flex;
  margin-top: 50px;
  cursor: pointer;

  .headerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 500px; // Keep the maximum width for the buttons
    height: auto; // Allow button height to adjust to content
    padding: 15px 20px; // Adjust padding to give some space around the content
    margin: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 42px;
    background-image: linear-gradient(to left, $blue, $dark_blue);
    box-shadow: 0px 14px 10px -2px rgba(110, 191, 254, 0.4);

    &.yellow {
      background-image: linear-gradient(258deg, $yellow, $dark_yellow);
      box-shadow: 0px 14px 10px -2px rgba(241, 149, 51, 0.4);
    }

    .app-link-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start; // Align text to the start (left side)
      justify-content: center;
    }

    img {
      height: 2em; // Example height, adjust as needed
      width: auto; // Maintain aspect ratio
      margin-right: 20px; // Give some horizontal space between the image and the text
    }
  }

  /* Maintain your sub-header and header styles as they were */
  .sub-header,
  .header {
    display: block;
    text-align: left; // Align text to the left
    margin: 0; // Remove margin if any
  }

  .sub-header {
    font-size: 0.75em; // Smaller text for the sub-header
  }

  .header {
    font-size: 1em; // Larger text for the main header
  }
}

.content {
  background-color: $kinda-white;
  padding: 0 10px;

  .desc {
    font-family: Geometria !important;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }

  #header {
    background: url('../../resources/images/header-bg.svg') no-repeat right center;
    background-position-x: calc(100% + 66px);
    background-position-y: -80px;
    background-size: 920px;
    padding-bottom: 150px;
    overflow: hidden;

    .sub-title {
      font-weight: 700;
    }

    .title {
      font-size: 40px;
      margin: 15px 0 30px;
      line-height: 1.1;
      letter-spacing: -0.16px;
    }

    h1.title>em {
      font-size: 40%;
      color: $dark_grey;
      font-style: normal;
    }

    .mouse {
      margin-top: 100px;
    }
  }

  #section1 {
    padding: 80px 0 0 0;

    >.title {
      font-size: 36px;
      margin-bottom: 50px;
      text-align: center;
      padding-bottom: 30px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 93px 30px 30px 30px;
      box-shadow: none;
      border-radius: 10px;
      height: 316px;

      .title,
      .desc {
        text-align: center;
      }

      img {
        width: 121px;
        height: 131.47px;
        position: absolute;
        top: -16%;
      }

      .wrapper {
        margin-bottom: 20px;
        background-size: 50px;

        &.second {
          background-size: 50px;
        }

        &.third {
          background-size: 50px;
        }

        .title {
          font-size: 22px;
          margin-bottom: 10px;
        }
      }
    }
  }

  #testimonials .users .desc {
    font-size: 16px;
    /* increased font size */
    font-weight: 500;
    line-height: 28px;
    /* increased line height */
    margin-top: 44px;
    white-space: pre-wrap;
    background-color: #f9f9f9;
    /* subtle background color */
    padding: 20px;
    /* padding around the text */
    border-radius: 8px;
    /* rounded corners for bubble effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* optional shadow */
    //max-width: 90%; /* constrain the width for large screens */
    //margin: 44px auto; /* centering the block */
  }

  #section2 {
    margin-top: 50px;
    position: relative;

    .title {
      margin-top: 40px;
      margin-bottom: 17px;
    }

    .desc {
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      margin-bottom: 38px;
    }

    .steps {
      font-size: 22px;
      font-style: italic;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: -0.07344843447208405px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      .count {
        font-size: 40px;
        font-style: italic;
        font-weight: 700;
        line-height: 54px;
        text-align: center;
        color: #3ea8fb;
        opacity: 0.3;
        margin-right: 15px;
      }
    }

    .unbPath {
      position: absolute;
      top: 160px;
      left: calc(4vw + 180px);
    }

    .column.is-half {
      flex: block;
    }
  }

  .subSection {
    margin: 50px 0;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('../../resources/images/get-app-back.png') no-repeat right;
    background-size: cover;
    background-color: white;
    background-size: contain;
    box-shadow: 0 22px 24px 0 rgba(196, 196, 196, 0.05);
    border-radius: 15px;
    padding: 50px 0;
    margin-top: 100px;
    position: relative;
    overflow: hidden;

    .getAppImg {
      position: absolute;
      left: 10px;
      top: -10px;
      width: 50%;
    }

    .title {
      display: flex;
      align-items: center;
      text-align: center;

      img {
        margin-left: 20px;
      }
    }

    .app-store {
      display: flex;
      justify-content: space-around;
      width: 280px;
      font: bold 24px Geometria;
      text-align: left;
      margin: 15px 10px;
      border: 3px dashed $blue;
      border-radius: 5px;
      padding: 15px 40px 20px 10px;

      img {
        object-fit: contain;
      }

      span {
        opacity: 0.6;
        font: $font-weight-roboto-medium 15px Roboto;
        color: $grey;
        line-height: 1.8;
        letter-spacing: 0.35px;
      }

      .active {
        cursor: pointer;
      }
    }
  }

  #section3 {
    margin-top: 150px;

    .signup {
      text-align: left;

      .title {
        line-height: 1.35;
        margin-bottom: 28px;
      }

      .desc {
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
        color: $grey;
      }

      .signupBtn {
        background-image: linear-gradient(258deg, $yellow, $dark_yellow);
        box-shadow: 0px 14px 10px -2px rgba(241, 149, 51, 0.4);
        margin-top: 50px;
        width: 250px;
        text-align: center;
        padding: 15px 0 10px;
        border-radius: 40px;
        cursor: pointer;

        a {
          display: inline-block;
          font-family: Geometria;
          font-size: 18px;
          font-weight: bold;
          color: $white;
        }
      }
    }

    .signup-description {
      display: flex;
      flex-direction: column;

      .description-block:nth-child(1) {
        align-self: end;

        .description {
          background: url('../../resources/images/1.png') no-repeat left top;
        }
      }

      .description-block:nth-child(2) {
        align-self: center;

        .description {
          background: url('../../resources/images/2.png') no-repeat left top;
        }
      }

      .description-block:nth-child(3) {
        align-self: start;

        .description {
          background: url('../../resources/images/3.png') no-repeat left top;
        }
      }

      .description-block {
        display: flex;
        margin-bottom: 33px;

        img {
          height: 119px;
          width: 103px;
        }

        .description {
          padding-left: 25px;
          padding-top: 15px;

          .title {
            font-size: 22px;
            margin-bottom: 10px;
          }

          p {
            max-width: 439px;
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            text-align: left;
            color: $main_body_text_color;
          }
        }
      }
    }

    .signupBtn.mobile {
      display: none;
    }
  }

  hr {
    background: $medium_grey;
    margin: 40px 0 0;
  }

  .title {
    font: bold 32px Geometria;
    color: $dark_grey;

    .yellow {
      color: $dark_yellow;
    }

    .blue {
      color: $dark_blue;
    }
  }

  .desc {
    color: $main_body_text_color;
    line-height: 27px;
    letter-spacing: 0.35px;
  }

  .top-wrapper {
    background: url('../../resources/images/top-wrapper-back.png') no-repeat;
    background-position-y: -175px;
  }

  .bottom-wrapper {
    padding-top: 150px;
    background: url('../../resources/images/bottom-wrapper-back.png') no-repeat;
  }
}

@media screen and (max-width: 1900px) {
  .content {
    #section2 {
      .unbPath {
        left: calc(4vw + 150px);
      }
    }
  }
}

@media screen and (max-width: 1820px) {
  .content {
    #section2 {
      .unbPath {
        left: calc(4vw + 100px);
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  .content {
    #section2 {
      .unbPath {
        left: calc(4vw + 50px);
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .content {
    #section2 {
      .unbPath {
        left: calc(3vw + 50px);
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .content {
    #section2 {
      .unbPath {
        height: 450px;
        left: calc(3vw + 50px);
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  #section1 {
    .card {
      height: 362px !important;
    }
  }
}

@media screen and (max-width: 1450px) {
  .content {
    #section2 {
      .unbPath {
        left: calc(3vw + 50px);
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .content {
    #section2 {
      .unbPath {
        height: 500px !important;
        width: 90%;
        left: calc(3vw + 0px);
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  #section1 {
    .card {
      height: 380px !important;
    }
  }
}

@media screen and (max-width: 1250px) {
  .content {
    #section2 {
      .unbPath {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .content {
    #section2 {
      .unbPath {
        top: 120px;
      }
    }
  }
}

@media screen and (max-width: 1452px) {
  .content {
    #header {
      padding-bottom: 50px;
      background-size: 700px;
      // background-position-x: 50%; /* Center horizontally */
      background-position-y: 10%;
      /* Adjust the vertical position */
    }

    #section1 {
      padding-top: 20px;

      .card {
        align-items: center;

        .title {
          font-size: 22px;
        }

        .desc {
          font-size: 15px;
        }
      }
    }

    .top-wrapper {
      background-position-y: -280px;
    }
  }
}

@media screen and (max-width: 1088px) {
  #section1 {
    .card {
      height: 320px !important;
    }
  }

  .content {
    #header {
      text-align: center;

      background-size: 600px;
      /* Further reduce the background size */
      background-position-y: 80%;

      .desc {
        text-align: center;
      }

      .container {
        padding: 0 40px;
      }

      .mouse {
        display: none;
      }
    }

    #section1 {
      padding: 50px 23px 0 23px;

      .columns {
        .column:nth-child(3) {
          margin-left: 25%;
        }
      }
    }

    #section2 {
      padding: 30px 34px;

      .unbPath {
        display: none;
      }

      .container {
        box-sizing: unset;

        .work-desc {
          width: 80%;
          margin: 0 auto;
        }
      }

      .columns {
        justify-content: center;
      }

      .work-desc {
        text-align: center;
      }
    }

    .subSection {
      width: 80%;
      margin: 50px auto;
      background-size: cover;

      >.title {
        img {
          display: none;
        }
      }

      .app-store {
        width: 220px;
        padding: 10px 20px 20px 0;
        font-size: 22px;
      }
    }

    #section3 {
      .columns {
        flex-direction: column;
        align-items: center;

        .column {
          width: 80%;
          margin: 0 auto;
        }
      }

      .signup {
        text-align: center;

        .signupBtn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .top-wrapper {
      background-position-y: -220px;
    }
  }
}

@media screen and (max-width: 768px) {

  /* Adjust the order of the columns for mobile */
  .columns.is-multiline {
    display: flex;
    flex-direction: column;
  }

  /* Target the column that contains the app link group */
  .column.is-5-desktop {
    order: 2;
    /* Will be moved below the slider on mobile */
  }

  /* Target the column that contains the slider */
  .slider-wrapper.column.is-7-desktop {
    order: 1;
    /* Will be displayed above the app link group on mobile */
  }

  .content {
    #header {
      text-align: center;

      background-size: 500px;
      /* Even smaller background for very small devices */
      background-position-y: 30%;

      .title {
        font-size: 35px;
      }

      .desc {
        font-size: 16px;
      }

      .slider-wrapper {
        margin-top: 20px;
      }
    }

    #section1 {
      .title {
        font-size: 30px;
        margin-bottom: 20px;
      }

      .columns {
        .column:nth-child(3) {
          margin-left: 0;
        }
      }
    }

    #section2 {
      padding: 0;

      .work-desc {
        .title {
          font-size: 35px;
        }
      }

      .subSection {
        >.title {
          margin: 20px 0 40px;
          font-size: 28px;
        }
      }
    }

    #section3 {
      margin-top: 0;
    }

    .top-wrapper {
      background-position-y: -40px;
    }
  }
}

@media screen and (max-width: 648px) {
  .content {
    padding: 0;
    overflow-y: hidden;

    .top-wrapper {
      padding: 0 20px;
    }

    #header {
      padding-bottom: 45px;
      background-size: 455px;
      /* Continue to adjust as needed for your design */
      background-position-y: 7%;

      text-align: center;

      .container {
        padding: 0 10px;
      }

      .line {
        width: 53px;
        height: 5px;
      }

      .title {
        font-size: 2.5rem;
        /* Adjust size as needed */
        color: #333;
        /* A strong, readable color */
      }

      .title .yellow {
        color: #f0ad4e;
        /* Highlight color for 'and save' */
        font-style: italic;
      }

      .title strong {
        font-weight: bold;
        color: #f0ad4e;
        /* Or whatever your brand's highlight color is */
      }

      .sub-title {
        font-style: italic;
        color: #666;
        /* Subdued color for less important subtitle */
      }

      .desc {
        font-size: 1rem;
        /* Smaller than the title for visual hierarchy */
        color: #666;
        /* A softer color for less immediate information */
      }

      .desc .highlight {
        font-weight: bold;
        color: #333;
        /* Optional: Could be your brand color for emphasis */
      }

      .desc strong {
        font-weight: bold;
      }

      .btnGroup {
        display: flex;
        gap: 20px;
        justify-content: center;
        /* This centers the children horizontally */
        align-items: center;
        /* This centers the children vertically (if needed) */
        // flex-wrap: wrap; /* This allows children to wrap to new lines as needed */
        margin-top: 18px;

        .headerBtn {
          width: 100%;
          max-width: 500px;
          /* Sets a maximum width for the buttons */
          height: 60px;
          font-size: 17px;
          padding: 21px 0 15px;
          margin: 0 10px;
          /* Adds horizontal spacing between buttons */
          box-sizing: border-box;
          /* Includes padding and border in the element's total width and height */

          &.yellow {
            margin-top: 10px;
          }
        }
      }
    }

    #section1 {
      .title {
        font-size: 20px;
        padding-bottom: 30px;
        margin-bottom: 40px;
      }

      .card {
        .wrapper {
          padding-top: 13px;
          margin-bottom: 0;

          .title {
            text-align: center;
            font-size: 18px;
            margin: 0;
          }

          .desc {
            line-height: 1.75;
            letter-spacing: 0.28px;
            font-size: 16px;
          }
        }
      }
    }

    .bottom-wrapper {
      padding-top: 106px;
      background-position-y: 60px;
    }

    #section2 {
      margin-top: 0;
      padding-top: 0;

      .container {
        padding: 0 23px;

        .columns {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      .work-desc {
        padding: 0;

        .title {
          font-size: 21px;
          margin-bottom: -1px;
        }

        .desc {
          font-size: 16px;
        }
      }

      .subSection {
        background-position: 53% 40px;
        padding: 45px 30px 50px;

        .getAppImg {
          width: 100%;
          height: 33px;
          object-fit: contain;
        }

        >.title {
          margin: 0;
          margin-bottom: 40px;
          padding: 0 30px;
          font-size: 20px;

          .line {
            display: none;
          }
        }

        .app-store {
          width: 200px;
          border: 3px dashed rgba(108, 176, 253, 0.3);
          padding: 20px 42px 28px 32px;
          margin-bottom: 5px;
          justify-content: left;
          align-items: center;

          img {
            width: 40px;
            height: 48px;
            object-fit: contain;
          }

          >div {
            font-size: 18px;
            margin-left: 25px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    #section3 {
      margin-top: 20px;
      padding: 0 20px;

      .signup {
        .title {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        .desc {
          line-height: 1.6;
          letter-spacing: 0.35px;
        }

        .signupBtn {
          display: none;
        }
      }

      .signupBtn.mobile {
        display: block;
        background-image: linear-gradient(258deg, $yellow, $dark_yellow);
        box-shadow: 0px 14px 10px -2px rgba(241, 149, 51, 0.4);
        margin: 0 auto 100px;
        width: 250px;
        text-align: center;
        padding: 15px 0 10px;
        border-radius: 40px;

        a {
          display: inline-block;
          font-family: Geometria, sans-serif;
          font-size: 18px;
          font-weight: bold;
          color: $white;
        }
      }
    }

    hr {
      margin: 20px 0 0;
    }

    .block {
      display: block;
    }
  }

  .save-your-skin {
    .title {
      font-size: 20px;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 465px) {
  #section1 {
    .card {
      height: 450px !important;
    }
  }
}

.m-0-auto {
  margin: 0 auto;
}

.yellow {
  color: $dark_yellow;
}