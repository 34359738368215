@import 'config/colors.scss';

#footer {
  padding: 50px 0;

  .level {
    display: flex;

    img.forpc {
      width: 30%;
    }
  }

  .footer-links {
    a {
      font: bold 16px Geometria;
      color: $light_grey;
      text-transform: uppercase;
      padding:0 10px;
    }
  }

  .mobile{
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  #footer {
    .mobile {
      display: block; // Changed from flex to block for a single image
      margin: 0 auto; // Center the logo image when displayed on mobile
    }

    .forpc {
      display: none;
    }

    .level {
      flex-wrap: wrap; // Allow the container to wrap its children
      align-items: center;
      justify-content: center; // Center the items horizontally
    }


  }
}




@media screen and (max-width: 650px) {
  #footer {
    .level {
      flex-direction: column; // Stack the logo and links vertically
    }

    .footer-links {
      a {
        padding: 0;
        margin: 10px 0; // Add vertical space between links
        text-align: center; // Center-align the text for each link
        display: block; // Each link takes the full width
      }
      &.level-right {
        justify-content: center; // Center the links container
      }
    }
  }
}



@media screen and (min-width: 1000px) {
  #footer{
    padding: 27px 20px 31px;
    line-height: 15px;

    .level {
      width: 100%;
      margin: 0;

      .level-left {
        flex-shrink: 1;
      }
    }
  }
}