
.check-mark {
  height: 30px;
  min-width: 30px;
  border-radius: 30px;
  margin-right: 10px;

  background: linear-gradient(270deg, #69BEFC 0%, #3CA7FB 100%);

  img {
    width: 15px;
    position: absolute;
    top: 10px;
    left: 8px;
  }
}
