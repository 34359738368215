@import 'config/colors.scss';

.app-link {
  display: flex !important;
  flex-direction: row !important;
  width: 210px;
  justify-content: center;
  font-weight: 700;
  padding-top: 17px !important;
  cursor: pointer;

  img {
    margin-right: 15px;
  }
}

.app-link-title {
  color: $white !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 18px;
}

.sub-header {
  font-size: 11px;
  opacity: 0.6;
}

.app-link-group {
  min-width: 500px;
  margin-top: 35px !important;
  justify-content: center;
  gap: 10px;
}

@media screen and (max-width: 648px) {
  .app-link-group {
    flex-direction: column;
    align-items: center;
    min-width: auto;
  }

  .app-link:first-child {
    margin-bottom: 20px;
  }
}
