@import "~bulma/bulma";

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.requests-container {
  min-height: 100vh;
}

.content-wrapper {
  min-height: calc(100vh - 300px);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.modal-overlay {
  overflow: auto;
  position: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.forpc, .mobile {
  & img {
    cursor: pointer;
  }
}

.m-0-auto {
  margin: 0 auto;
}

.inline {
  display: inline;
}

/* @media screen and (max-width: 1472px) {}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1088px) {}
@media screen and (max-width: 1000px) {}
@media screen and (max-width: 650px) {} */

